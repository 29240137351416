import { Component } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProductNaturesDialogComponent } from './user/product-natures.dialog.user.component';

import { AuthenticationService, RouteService } from '../services';
import { LocationDialogComponent } from './user/location.dialog.user.component';
import { RoleEnum } from '../enums/role.enum';
import { ProfileInfoDialogComponent } from './user/profileinfo.dialog.user.component';
import { validateSession } from '../helpers/session-storage/sessionStorage';

@Component({
  selector: 'app',
  templateUrl: '../views/app.component.html',
  styleUrls: ['../../assets/css/app.component.css'],
})
export class AppComponent {
  currentUser: any;
  title = 'Marketplace0';
  productNaturesLocationBothMissing: boolean = false;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    public routeService: RouteService,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.currentUser.subscribe((x) => {
      this.currentUser = x;

      // if user logged in
      if (this.currentUser && !this.routeService.isSignupSignPage) {
        // promp retailer information
        if (this.authenticationService.currentUserRole === RoleEnum.retailer) {
          if (
            !this.currentUser.locationId &&
            !this.currentUser.productNatures
          ) {
            const dialogRef = this.dialog.open(ProductNaturesDialogComponent);
            // dialogRef.afterClosed().subscribe(result => {
            //   this.dialog.open(LocationDialogComponent);
            // })

            return;
          }

          if (!this.currentUser.locationId) {
            // this.dialog.open(LocationDialogComponent);
            return;
          }

          if (
            !this.currentUser.productNatures ||
            this.currentUser.productNatures.length === 0
          ) {
            this.dialog.open(ProductNaturesDialogComponent);
          }

          return;
        }

        // prompt consumer information
        if (this.authenticationService.currentUserRole === RoleEnum.consumer) {
          if (
            !this.currentUser.profilePictureUrl &&
            !this.currentUser.productNatures
          ) {
            const dialogRef = this.dialog.open(ProductNaturesDialogComponent);
            dialogRef.afterClosed().subscribe((result) => {
              if (!validateSession('skip_profile_update', false)) {
                this.dialog.open(ProfileInfoDialogComponent);
              }
            });

            return;
          }

          if (
            !this.currentUser.profilePictureUrl &&
            !validateSession('skip_profile_update', false)
          ) {
            this.dialog.open(ProfileInfoDialogComponent);
            return;
          }

          if (
            !this.currentUser.productNatures ||
            this.currentUser.productNatures.length === 0
          ) {
            this.dialog.open(ProductNaturesDialogComponent);
          }
        }
      }
    });
  }
}
